.lazy-load-image-background.black-and-white {
  filter: grayscale(1);
}

.lazy-load-image-background.black-and-white.lazy-load-image-loaded {
  filter: grayscale(0);
  transition: filter .3s;
}

.lazy-load-image-background.black-and-white > img {
  opacity: 0;
}

.lazy-load-image-background.black-and-white.lazy-load-image-loaded > img {
  opacity: 1;
  transition: opacity .3s;
}
