*, *:before, *:after {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

.container {
  width: 100vw;
  height: 100vh;
}
